<script>
export default {
    name:"BaseViewButton",
    props:{
        isSelected:{
            type:Boolean,
            default:false
        },
        isDisable:{
            type:Boolean,
            default:false
        }
    },
    data:function(){
        return {}
    },
    methods:{
        doAction(){
            // const text = this.$slots.text[0].text;
            this.$emit("buttonClick");
        }
    }
}
</script>
<template>
  <div>
      <button
      class="btn"
      :disabled="isDisable"
      :class="{
          viewBtn:true,
          viewBtnFocus: isSelected
          }"
      @click="doAction"
      >
          <slot name="text" style="color: #007bff"></slot>
      </button>
  </div>
</template>

<style scoped>
    .btn{
        cursor:pointer;
        display:inline-block;
        font-weight:400;
        text-align:center;
        vertical-align: middle;
        font-size:1rem;
        padding:0.375rem 0.75rem;
        line-height: 1.5;
        margin-right:10px;
    }
 .viewBtn{
     color: #007bff;
     background-color: #fff;
     border: 1px solid #dee2e6;
     border-radius: 0.5rem;
 }

 .viewBtn:not(:disabled):not(.disabled):focus{
     background-color: #ddd;
     border-color: #dee2e6;
 }

 .viewBtnFocus{
    background-color:#ddd;
    border-color: #dee2e6;
 }
</style>

