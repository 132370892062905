

<script>
import UsCountyMapConfig from "../config/us-county-small";
// import mapCountyData from "../assets/test-county-data";
import Highcharts from 'highcharts'

export default {
  name: "MapCountyLevel",
  props: {
    mapData: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  computed: {
    lineData(){
        return Highcharts.geojson(UsCountyMapConfig, 'mapline');
    },
    borderLines() {
      return this.lineData.filter((d)=>{
            return d.properties['hc-group'] === '__border_lines__'
        });
    },
    separatorLines() {
      return this.lineData.filter((d) => {
        return d.properties["hc-group"] === "__separator_lines__";
      });
    },
    minDataPoint() {
      const valArr = this.mapData.map((d) => {
        return d.value;
      });
      const minval = Math.floor(Math.min.apply(Math, valArr));
      return minval;
    },
    chartOptions() {
      const vm = this;
      return {
        chart: {
          
          // borderWidth: 1,
        },
        title: {
          text: "Applicant's Demographic Distribution",
          margin: 50,
        },
        // subtitle: {
        //   text:
        //     'Source map: <a href="http://code.highcharts.com/mapdata/custom/world.js">World, Miller projection, medium resolution</a>',
        // },
        legend: {
          layout: 'vertical',
          align: 'right',
          floating: true,
          backgroundColor: 'rgba(255, 255, 255, 0.85)'
        },

        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
          enableMouseWheelZoom: false,
        },
        colorAxis: {
          type: "linear",
          endOnTick: false,
          startOnTick: false,
          min: vm.minDataPoint,
          minColor: "#DEDDDD",
          maxColor: "#BF0B0B",
          stops: [
            [0, "#DEDDDD"],
            [0.3, "#F5A83F"],
            [1, "#BF0B0B"],
          ],
        },
        plotOptions: {
          series: {
            events: {
              click: function (e) {
                return vm.clickCounty(e, this);
              },
            },
          },
          mapline: {
            showInLegend: false,
            enableMouseTracking: false
          }
        },
        series: [
          {
            data: vm.mapData,
            mapData: UsCountyMapConfig,
            name: vm.$store.getters.getSelectedVariable,
            joinBy: ['hc-key', 'code'],
            tooltip: {
                // valueSuffix: '%'
            },
            borderWidth: 0.5,
            shadow: false,
            allowPointSelect: true,
            states: {
              hover: {
                color: "#BADA55",
              },
              select: {
                color: "#a4edba",
                borderColor: "black",
                dashStyle: "shortdot",
              },
            },
            cursor: "pointer",
            // dataLabels: {
            //   enabled: true,
            //   format: "{point.name}",
            // },
          },
          {
            type: "mapline",
            name: "State borders",
            data: vm.borderLines,
            color: "white",
            shadow: false,
          },
          {
            type: "mapline",
            name: "Separator",
            data: vm.separatorLines,
            color: "gray",
            shadow: false,
          },
        ],
      };
    },
  },
  methods: {
    clickCounty(e, clickedObj) {
      this.$emit("onClickCounty", { event: e.point });
      let text;
      if (!e.point.selected) {
        text =
          "<b style='font-size:14px'>Selected Region: </b>" +
          "<b style='font-size:13px; color:#2F56CF'>" +
          e.point.name +
          "</b>" +
          "<b style='font-size:14px'>; Data: </b>" +
          "<b style='font-size:13px; color:#2F56CF'>" +
          clickedObj.name +
          "</b>" +
          "<b style='font-size:14px'>; Value: </b>" +
          "<b style='font-size:13px; color:#2F56CF'>" +
          e.point.value +
          "</b>" +
          "<br> (see region breakdown below)";
        // text =
        //   "<b style='font-size:15px'>Selected Region: </b>" + "<b style='font-size:13px; color:#2F56CF'>"+
        //   e.point.name + "</b>"+
        //   "<br><b style='font-size:15px'>Data: </b>" + "<b style='font-size:13px; color:#2F56CF'>"+
        //   clickedObj.name + "</b>" +
        //   "<br><b style='font-size:15px'>Value: </b>" + "<b style='font-size:13px; color:#2F56CF'>"+
        //   e.point.value + "</b>" + "<br>"+ "(see bar chart below)";
      } else {
        text = null;
      }

      if (!clickedObj.chart.clickLabel) {
        clickedObj.chart.clickLabel = clickedObj.chart.renderer
          .label(text, 0, 0)
          .css({
            width: "500px",
            transform: "translate(42vw, 4vh) translateX(-200px)",
            "z-index": "100",
          })
          .add();
      } else {
        clickedObj.chart.clickLabel.attr({
          text: text,
        });
      }
    },
    clearStateSelection() {
      this.$emit("clickClearRegion");
    },
  },
};
</script>
<template>
  <div class="map-container">
    <!-- <button @click="clearStateSelection">Clear Selected State</button> -->
    <highcharts
      :constructor-type="'mapChart'"
      :options="chartOptions"
      class="map"
    ></highcharts>
  </div>
</template>
<style scoped>
.popuptext1 {
  font-size: 16px;
}
.popuptext2 {
  font-size: 14px;
  color: #2f56cf;
}
.map {
  /* margin-left: auto;
  margin-right: auto; */
  /* min-height: 850px; */
  height:calc(50vw - 200px);
  /* width: calc(85vw - 20px); */
}
/* .map-container {
  height: calc(100vh - 200px);
} */
</style>