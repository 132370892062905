<script>
// @ is an alias to /src
import BarChart from "@/components/BarChart.vue";
import Map from "@/components/Map.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import barData from "../assets/demographic_state_plot"
import mapdata from "../assets/demographic_state_map"
import barCountyData from "../assets/demographic_county_plot"
import mapCountyData from "../assets/demographic_county_map"
import BaseViewButton from "@/components/_base-view-button.vue"
import MapCountyLevel from "@/components/MapCountyLevel"
export default {
  name: "Applicants",
  components: {
    // HelloWorld
    BarChart,
    Map,
    BaseViewButton,
    MapCountyLevel
  },
  data: function () {
    return {
      yearData: [
        { key: 2015, value: "2015" },
        { key: 2016, value: "2016" },
        { key: 2017, value: "2017" },
        { key: 2018, value: "2018" },
        { key: 2019, value: "2019" },
        { key: 2020, value: "2020" },
      ],
      selectedYear: "2020",
      variableData: [
        { key: "age", value: "Average Age" , xLabel: "Age"},
        { key: "income", value: "Average Income", xLabel: "Income" },
      ],
      selectedVariable: "Average Age",
      selectedRegion:null,
      selectedStateDisplay:null,
      selectedCounty:null,
      selectedMapView: "state",
      selectedCountyDisplay: null
    };
  },
  computed: {
    ...mapState("index", ["yearSelection", "variableSelection"]),
    ...mapGetters("index", ["getYearSelection", "getSelectedVariable"]),
    mapTitle(){
      return "Applicant's Demographic Distribution";
    },
    mapDataLabel(){
      return this.$store.getters.getSelectedVariable;
    },
    barLegend(){
        return this.variableData.find((d)=>{
            return d.value === this.$store.getters.getSelectedVariable
        }).xLabel
    },
    barChartTitle(){
        let text;
        if(this.$store.getters.getSelectedVariable === 'Average Age')
            text = this.$store.getters.getSelectedVariable + " Distribution in " + this.selectedStateDisplay
        else
            text = this.$store.getters.getSelectedVariable + " Distribution in " + this.selectedStateDisplay + " (Monthly Income)"
        return text;
    },
    barChartTitleCounty(){
      let text = this.$store.getters.getSelectedVariable + " Distribution in " + this.selectedCountyDisplay
      return text;
    },
    calculatedMapCountyData(){
      const selectedVariableKey = this.variableData.find(
        (d) => d.value === this.$store.getters.getSelectedVariable
      ).key;
      return mapCountyData.find((d)=>{
        return d.year === parseInt(this.$store.getters.getYearSelection)
          && d.variable === selectedVariableKey
      }).mapData
    },
    mapData(){
      const selectedVariableKey = this.variableData.find(
        (d) => d.value === this.$store.getters.getSelectedVariable
      ).key;
      return mapdata.find((d)=>{
        return d.year === parseInt(this.$store.getters.getYearSelection)
          && d.variable === selectedVariableKey
      }).mapData
    },
    barData() {
      const selectedVariableKey = this.variableData.find(
        (d) => d.value === this.$store.getters.getSelectedVariable
      ).key;
      
      return barData.filter((d) => {
        return (
          d.year === parseInt(this.$store.getters.getYearSelection) 
           && d.variable === selectedVariableKey
           && d.state === this.selectedRegion
        );
      });
    },
    barCountyData(){
       const selectedVariableKey = this.variableData.find(
        (d) => d.value === this.$store.getters.getSelectedVariable
      ).key;
       return barCountyData.filter((d) => {
        return (
          d.year === parseInt(this.$store.getters.getYearSelection) 
           && d.variable === selectedVariableKey
           && d.code === this.selectedCounty
        );
      });
    }
  },
  methods: {
    ...mapMutations("index", ["setSelectedYear", "setSelectedVariable"]),
    handleMapStateClick(d) {
      if(!d.event.selected)
        {this.selectedRegion = d.event['hc-key'];
        this.selectedStateDisplay = d.event.name
        }
      else{
        this.selectedRegion = null
        this.selectedStateDisplay = null;
      }
    },
    handleCountyClick(d){
      this.selectedCounty = d.event.code
      this.selectedCountyDisplay = d.event.options.name;
    },
    onChangeYearSelection(e) {
      this.$store.commit("setSelectedYear", e);
    },
    onChangeVariableSelection(e) {
      this.$store.commit("setSelectedVariable", e);
    },
    clearRegionSelection(){
      if(this.selectedRegion !== null){
        this.selectedRegion = null;
      }
    },
    onViewChange($event, view){
      this.selectedMapView = view
    }
  },
};
</script>
<template>
  <div class="home" id="home">
    <div id="navbar">
      <div id="navRowContainer">
        <div id="navRow">
          <div class="col-10 nav-text">Select a year:</div>
          <div class="col-10">
            <el-select
              ref="yearDropdown"
              v-model="selectedYear"
              :placeholder="'Select a year'"
              @change="onChangeYearSelection"
              clearable
            >
              <el-option
                v-for="option in yearData"
                :key="option.key"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div id="navRow">
          <div class="col-10 nav-text">Select a demographic variable:</div>
          <div class="col-10">
             <el-select
            ref="yearDropdown"
            v-model="selectedVariable"
            :placeholder="'Select a variable'"
            @change="onChangeVariableSelection"
            clearable
          >
            <el-option
              v-for="option in variableData"
              :key="option.key"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
          </div>
          <!-- <el-select
            ref="yearDropdown"
            v-model="selectedVariable"
            :placeholder="'Select a variable'"
            @change="onChangeVariableSelection"
            clearable
          >
            <el-option
              v-for="option in variableData"
              :key="option.key"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select> -->
        </div>
      </div>
    </div>
    <div id="content">
      <div id="view-btn-container">
        <BaseViewButton 
        :isSelected="selectedMapView === 'state'"
        @buttonClick="onViewChange($event, 'state')">
        <template v-slot:text>
          State Map
        </template>
      </BaseViewButton>
       <BaseViewButton 
       :isSelected="selectedMapView === 'county'"
       @buttonClick="onViewChange($event, 'county')">
        <template v-slot:text>
          County Map
        </template>
      </BaseViewButton>
      </div>
      <div id="map-container">
        <Map 
          v-show ="selectedMapView === 'state'"
          :mapData="mapData"
          :dataLabel="mapDataLabel"
          :mapType="All"
          :mapTitle="mapTitle"
          @onClickState="handleMapStateClick"
          @clickClearRegion="clearRegionSelection"></Map>
          <!--  -->
        <MapCountyLevel
        v-show="selectedMapView === 'county'"
        :mapData="calculatedMapCountyData"
        @onClickCounty="handleCountyClick"
        ></MapCountyLevel>
      </div>
        <div v-if="selectedRegion !== null" id="barchart-container"
        v-show ="selectedMapView === 'state'"
        >
          <BarChart 
          :chartData="barData" 
          :plotVar="'number'"
          :chartTitle="barChartTitle"
          :getChartLegend="barLegend"
          :yAxisLabel="'Percentage (%)'"
          :selectedState="selectedStateDisplay" v-if="selectedRegion !== null"></BarChart>
        </div>
        <div v-if="selectedCounty !== null" id="barchart-container"
        v-show ="selectedMapView === 'county'"
        >
          <BarChart 
          :chartData="barCountyData" 
          :plotVar="'number'"
           :chartTitle="barChartTitleCounty"
           :getChartLegend="barLegend"
           :yAxisLabel="'Percentage (%)'"
          :selectedState="selectedCounty" v-if="selectedCounty !== null"></BarChart>
        </div>
    </div>
  </div>
</template>
<style scoped>
.nav-text{
  display:flex;
  justify-content: flex-start;
  font-size:16px;
  font-weight:700;
  text-align: start;
}
#home {
  display: flex;
  flex-direction: row;
}
#navbar {
  /* max-width: 400px;
  min-width:250px; */
  width:calc(15vw);
  max-width:400px;
  min-width:calc(12vw);
  /* min-width:calc(10vw); */
  /* background-color: #f2f2f2; */
  min-height: 500px;
  height:calc(100vh - 56px);
  margin-left: -8px;
}
#navRowContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  margin: 20px 8px;
}
#navRow {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  /* 10px 0px */
  width: 92%;
  /* 85% */
}
#content {
  /* width: calc(85vw - 10px); */
  width: calc(90vw - 10px);
  margin-top: 30px;
}
#barchart-container{
  /* margin-top: 20px; */
  /* margin-left: 15%; */
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  /* width: 30%; or 70%; */
}
#map-container{
  width:calc(85vw-40px);
  padding-left: calc(5vw);
  /* padding-bottom:10px; */
  /* height:calc(100vh - 100px); */
}
#view-btn-container{
  width:100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding:10px 0px;
}
</style>
